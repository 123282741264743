@import url('https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap');

.card-start {
    margin-top: 1.2rem;
}

.card {
    border: none;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
    border-radius: 10px;
    min-height: 450px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.card.card-has-bg {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
}

.card.card-has-bg:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

.card.card-has-bg:hover {
    transform: scale(0.98);
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
    background-size: 130%;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.card.card-has-bg:hover .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(35, 79, 109);
    background: linear-gradient(0deg, rgba(255, 165, 0, .3) 0%, rgba(225, 225, 225, 0.3) 100%);
}

.card .card-footer {
    background: none;
    border-top: none;
}

.card .card-footer .media img {
    border: solid 3px rgba(234, 95, 0, 0.3);
}

.card .card-meta {
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.card .card-body {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.card:hover {
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.card:hover .card-body {
    margin-top: 30px;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.card .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: #234f6d;
    background: linear-gradient(0deg, rgba(100, 100, 100) 0%, rgba(46, 47, 53, 0.2) 100%);
}

.price {
    color: rgb(255, 165, 0);
    font-size: 34px;
}

.price strong {
    display: none;
    font-family: 'Rubik Wet Paint', cursive;
}

.card:hover strong {
    display: block;
}

.card:hover .card-footer {
    display: none;
}

@media screen and (max-width: 992px) {
    #mquery {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 600px) {
    #mquery {
        flex: 0 0 50%;
        max-width: 50%;
    }
}