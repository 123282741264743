.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 165, 0, 0.7);
    font-size: 3.6rem;
}
.homeBackground {
    position: fixed;
    top: 110px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.4;
    z-index: -1;
    min-width: 1920px;
    max-height: 1080px;
}

.description {
    color: #fafafa ;
}